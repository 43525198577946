import React, { ReactNode } from 'react';
import { Trans, useTranslation } from "react-i18next";
import { useAuthContext } from "../contexts/AuthContext";
import { ONBOARD_STAGES, useOnboardingContext } from "../contexts/OnboardingContext";
import styles from '../style/custom.module.css';
import GoogleLogin from "./googleLogin";

const CompleteSVG = () => (
    <svg className={styles.OnboardingStepper__progress__iconComplete} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 256 256" xmlSpace="preserve">
        <g style={{stroke: 'none', strokeWidth: 0, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'none', fillRule: 'nonzero', opacity: '1'}} transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
            <circle cx="45" cy="45" r="45" style={{stroke: 'none', strokeWidth: '1', strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'currentColor', fillRule: 'nonzero', opacity: '1'}} transform="matrix(1 0 0 1 0 0)" />
            <path d="M 37.899 62.038 c -0.529 0 -1.037 -0.21 -1.412 -0.584 L 22.599 47.606 c -0.782 -0.779 -0.784 -2.046 -0.004 -2.828 c 0.779 -0.783 2.046 -0.786 2.828 -0.004 l 12.424 12.389 l 26.68 -28.566 c 0.755 -0.807 2.021 -0.85 2.827 -0.096 c 0.808 0.754 0.851 2.02 0.097 2.827 L 39.36 61.403 c -0.37 0.396 -0.884 0.625 -1.426 0.635 C 37.923 62.038 37.911 62.038 37.899 62.038 z" style={{stroke: 'none', strokeWidth: '1', strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'rgb(255,255,255)', fillRule: 'nonzero', opacity: '1'}} transform="matrix(1 0 0 1 0 0)" strokeLinecap="round" />
        </g>
    </svg>
);

const InProgressSVG = () => (
    <svg className={styles.OnboardingStepper__progress__iconInProgress} viewBox="0 0 512 512"
         xmlns="http://www.w3.org/2000/svg">
        <circle cx="256" cy="256" r="208" fill="currentColor"/>
        <circle cx="256" cy="256" r="180" fill="#FFFFFF"/>
        <circle cx="256" cy="256" r="140" fill="currentColor"/>
    </svg>
);

interface OnboardingStepperProps {
  children?: ReactNode;
}

const OnboardingStepper: React.FC<OnboardingStepperProps> = ({ children }) => {
  // @ts-ignore TODO shouldn't have to do this, fix.
  const {t} = useTranslation();
  const {stage} = useOnboardingContext();
  const {connectedEmails} = useAuthContext();

  if (stage === ONBOARD_STAGES.COMPLETE) {
    return null;
  }

  let activeVideo:string | null = null;

  let stageIndex = 0;
  if (stage === 'GOOGLE_AUTH') {
    stageIndex = 2;
    // activeVideo = t('onboarding.connect.video');
  } else if (stage === 'GOOGLE_REAUTH') {
    stageIndex = 2;
    // activeVideo = t('onboarding.reconnect.video');
  } else if (stage === 'CONFIG_PREFERENCES') {
    stageIndex = 1;
    // activeVideo = t('onboarding.configure.video');
  } else if (stage === 'SUBSCRIBE') {
    stageIndex = 3;
    // activeVideo = t('onboarding.subscribe.video');
  }

  return (
    <section data-stage={stageIndex} className={styles.OnboardingStepper} style={{
      "--OnboardingStepper__stepText": `"${t('onboarding.step')}"`,
      "--OnboardingStepper__inProgressText": `"${t('onboarding.inProgress')}"`,
      "--OnboardingStepper__completedText": `"${t('onboarding.completed')}"`,
    } as React.CSSProperties}>
      {stage !== ONBOARD_STAGES.GOOGLE_REAUTH && (
        <ol className={styles.OnboardingStepper__progress}>
          <li data-active={stage === ONBOARD_STAGES.SIGNUP}>
            <div>
              <CompleteSVG/>
              <InProgressSVG/>
            </div>
            <div>{t('onboarding.signup.stepTitle')}</div>
          </li>
          <li data-active={stage === ONBOARD_STAGES.CONFIG_PREFERENCES}>
            <div>
              <CompleteSVG/>
              <InProgressSVG/>
            </div>
            <div>{t('onboarding.configure.stepTitle')}</div>
          </li>
          <li data-active={stage === ONBOARD_STAGES.GOOGLE_AUTH}>
            <div>
              <CompleteSVG/>
              <InProgressSVG/>
            </div>
            <div>{t('onboarding.connect.stepTitle')}</div>
          </li>
        </ol>
      )}
      <div className={styles.OnboardingStepper__activeStage}>
        {stage === ONBOARD_STAGES.SIGNUP && (
          <>
            <h1>{t('onboarding.signup.title')}</h1>
            <ol>
              {
                // @ts-ignore TODO fix
                t('onboarding.signup.infoList').map((item, index) => <li key={index}><Trans>{item}</Trans></li>)
              }
            </ol>
          </>
        )}
        {stage === ONBOARD_STAGES.GOOGLE_AUTH && (
          <>
            <h1>{t('onboarding.connect.title')}</h1>
            <ol>
              {
                // @ts-ignore TODO fix
                t('onboarding.connect.infoList').map((item, index) => <li key={index}><Trans>{item}</Trans></li>)
              }
            </ol>
            <GoogleLogin />
          </>
        )}
        {stage === ONBOARD_STAGES.GOOGLE_REAUTH && (
          <>
            <h1>{t('onboarding.reconnect.title')}</h1>
            <ol>
              {
                // @ts-ignore TODO fix
                t('onboarding.reconnect.infoList').map((item, index) => <li key={index}><Trans>{item}</Trans></li>)
              }
            </ol>
            <GoogleLogin />
          </>
        )}
        {stage === ONBOARD_STAGES.CONFIG_PREFERENCES && (
          <>
            <h1>{t('onboarding.configure.title')}</h1>
            <ol>
              {
                // @ts-ignore TODO fix
                t('onboarding.configure.infoList').map((item, index) => <li key={index}><Trans>{item}</Trans></li>)
              }
            </ol>
          </>
        )}
        {stage === ONBOARD_STAGES.SUBSCRIBE && connectedEmails && connectedEmails.length > 0 && (
          <>
            <h1>{t('onboarding.subscribe.title')}</h1>
            <ol>
            {t('onboarding.subscribe.infoList', { returnObjects: true }).map((item) => (
              <li>
                <Trans
                  components={{
                    gmailcta: <a href={`https://mail.google.com/mail/u/${connectedEmails[0].email}/#inbox`} target="_blank" rel="noopener noreferrer">Gets replaced</a>,
                  }}
                  shouldUnescape={true}
                >{item}</Trans>
              </li>
            ))}
            </ol>
          </>
        )}
      </div>
      {activeVideo && (
        <div
          className={styles.OnboardingStepper__video}
        >
          <iframe
            title={stage}
            sandbox="allow-scripts allow-same-origin"
            referrerPolicy="no-referrer"
            src={activeVideo}
            allowFullScreen={true}
          >
          </iframe>
        </div>
      )}
      {children}
    </section>
  );
};

export default OnboardingStepper;

import ReactDOM from 'react-dom/client';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

import './index.css';
import './translations';

import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (!process.env.REACT_APP_GMAIL_CLIENTID) {
  throw new Error('REACT_APP_GMAIL_CLIENTID is not defined');
}

// TODO - get CRA working with __webpack_nonce__ and use a nonce value for the prod build instead of unsafe-inline
const csp = `
  default-src 'self'; 
  connect-src 'self' www.googleapis.com ${process.env.REACT_APP_API_HOST} api.stripe.com analytics.google.com;
  script-src 'self' js.stripe.com accounts.google.com www.googletagmanager.com 'unsafe-inline'; 
  frame-src 'self' support.newmail.ai js.stripe.com; 
  child-src 'self' support.newmail.ai js.stripe.com; 
  style-src 'self' 'unsafe-inline';
`;

const Index = () => {
  // @ts-ignore TODO shouldn't have to do this, fix.
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta name="description" content={t('meta.description')} />
        <meta
          http-equiv="Content-Security-Policy"
          content={csp}/>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-5V8ECH8SCK"></script>
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                  dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', 'G-5V8ECH8SCK');
              `}
            </script>
      </Helmet>
      <App />
    </>
  );
}

root.render(<Index />);

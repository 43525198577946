import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { APIGet, APIPost } from "../helpers/http.helper";

import { useTranslation } from "react-i18next";
import { useAuthContext } from "./AuthContext";

export const ONBOARD_STAGES = {
    LOADING: 'LOADING',
    SIGNUP: 'SIGNUP',
    GOOGLE_AUTH: 'GOOGLE_AUTH',
    CONFIG_PREFERENCES: 'CONFIG_PREFERENCES',
    SUBSCRIBE: 'SUBSCRIBE',
    COMPLETE: 'COMPLETE',
    GOOGLE_REAUTH: 'GOOGLE_REAUTH',
} as const;

type Subscription = {
    description: string,
    type: string,
    price: string,
    expiresIn: number,
};

type OnboardingContextType = {
    stage: keyof typeof ONBOARD_STAGES
    subscription: Subscription | null | undefined,
    setHasConfiguredPreferences: React.Dispatch<boolean>,
};

const OnboardingContext = createContext<OnboardingContextType>({} as OnboardingContextType);

export const useOnboardingContext = (): OnboardingContextType => useContext(OnboardingContext);

export const OnboardingContextProvider = ({
    children
}: {
    children: ReactNode
}) => {
    // @ts-ignore TODO shouldn't have to do this, fix.
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { loggedIn, connectedEmails } = useAuthContext();

    const [hasConfiguredPreferences, setHasConfiguredPreferences] = useState<boolean | null>(null);
    const [subscription, setSubscription] = useState<Subscription | null | undefined>(undefined);

    const handleSubscriptionResponse = useCallback(async (promise: Promise<any>) => {
        try {
            const response = await promise;
            switch(response.status) {
                case 200:
                case 201: {
                    const newSubscription: Subscription = await response.json();
                    setSubscription(newSubscription)
                    break;
                }
                case 204: {
                    // User found but has no subscription.
                    setSubscription(null)
                    break;
                }
                default: {
                    throw new Error(`something went wrong`);
                }
            }
        } catch (error) {
            setSubscription(null);
            alert(t('profile.membership.errors.invalidStripeSession'));
        }
    }, [t]);

    useEffect(() => {
        if (loggedIn && connectedEmails) {
            // Get the user's subscription.
            (async() => {
                await handleSubscriptionResponse(APIGet(`subscription`));
            })();
        }
    }, [loggedIn, connectedEmails, handleSubscriptionResponse, navigate]);

    const sessionId = searchParams.get('success');

    useEffect(() => {
        if (loggedIn && connectedEmails && sessionId) {
            // User completed Stripe, post the sessionId to the BE for processing.
            (async() => {
                await handleSubscriptionResponse(APIPost(`subscription`,{sessionId}));
                window.dataLayer?.push({'event': 'user_subscribed'});
                navigate('/userProfile');
            })();
        }
    }, [sessionId, loggedIn, connectedEmails, handleSubscriptionResponse, navigate]);


    let stage: keyof typeof ONBOARD_STAGES = ONBOARD_STAGES.LOADING;

    if (loggedIn === false) {
        stage = ONBOARD_STAGES.SIGNUP;
    } else if (
        !connectedEmails ||
        loggedIn === null ||
        hasConfiguredPreferences === null ||
        (connectedEmails.length > 0 && connectedEmails[0].status === 'connected' && !subscription)
    ) {
        stage = ONBOARD_STAGES.LOADING;
    } else {
        if (!hasConfiguredPreferences) {
            stage = ONBOARD_STAGES.CONFIG_PREFERENCES;
        } else if (connectedEmails.length === 0) {
            stage = ONBOARD_STAGES.GOOGLE_AUTH;
        } else if (connectedEmails[0].status === 'disconnected'){
            stage = ONBOARD_STAGES.GOOGLE_REAUTH;
        } else if (!subscription) {
            stage = ONBOARD_STAGES.LOADING;
        } else if (subscription.type.toLowerCase() === 'trial' || subscription.expiresIn < 0) {
            stage = ONBOARD_STAGES.SUBSCRIBE;
        } else {
            stage = ONBOARD_STAGES.COMPLETE;
        }
    }

    const value = useMemo(() => ({
        stage,
        subscription,
        setHasConfiguredPreferences,
    }), [stage, subscription]);

    return (
        <OnboardingContext.Provider
            value={value}
        >
            {children}
        </OnboardingContext.Provider>
    );
};
